"use client";
import { Button } from "@/components/ui/button";
import { cn } from "@/lib/utils";
import NavLink from "@/components/shared/NavLink";
import {
  BarChartBig,
  BotIcon,
  BotMessageSquare,
  Code,
  MessagesSquareIcon,
  PackageIcon,
  SettingsIcon,
  Share,
} from "lucide-react";
import { useParams } from "next/navigation";
import useEmbed from "@/store/useEmbed";

export default function HeaderSubNavigation({
  className,
}: {
  className?: string;
}) {
  const { botId, orgId } = useParams();
  const { onOpenChange, shareOnOpenChange } = useEmbed();
  const subMenu = [
    {
      icon: <BarChartBig className="mr-2 h-4 w-4" />,
      label: "Dashboard",
      url: `/org/${orgId}/bot/${botId}`,
      exact: true,
    },

    {
      icon: <BotMessageSquare className="mr-2 h-4 w-4" />,
      label: "Playground",
      url: `/org/${orgId}/bot/${botId}/chat`,
      exact: true,
    },
    {
      icon: <MessagesSquareIcon className="mr-2 h-4 w-4" />,
      label: "Conversations",
      url: `/org/${orgId}/bot/${botId}/conversations`,
      exact: false,
    },
    {
      icon: <SettingsIcon className="mr-2 h-4 w-4" />,
      label: "Settings",
      url: `/org/${orgId}/bot/${botId}/settings`,
      exact: false,
    },
    {
      icon: <PackageIcon className="mr-2 h-4 w-4" />,
      label: "Sources",
      url: `/org/${orgId}/bot/${botId}/sources`,
      exact: true,
    },
    {
      icon: <Code className="mr-2 h-4 w-4" />,
      label: "Embed",
      onClick: () => onOpenChange(true),
      exact: true,
    },
    {
      icon: <Share className="mr-2 h-4 w-4" />,
      label: "Share",
      onClick: () => shareOnOpenChange(true),
      exact: true,
    },
  ];
  if (!botId) return null;
  return (
    <nav
      className={cn(
        "sub-menu",
        "flex gap-1 md:justify-center overflow-auto items-center px-6 dark:bg-gray-950",
        className,
      )}
    >
      {subMenu.map((item) => {
        const inner = (
          <>
            {item.icon}
            {item.label}
          </>
        );
        return (
          <Button
            key={item.label}
            className={cn(
              "text-gray-500 rounded-b-none",
              "flex py-3 hover:text-gray-900 self-end dark:text-gray-400 dark:hover:text-gray-50",
            )}
            variant="ghost"
            onClick={item.onClick}
            asChild={!!item.url}
          >
            {item.url ? (
              <NavLink
                exact={item.exact}
                activeClassName="active"
                href={item.url}
              >
                {inner}
              </NavLink>
            ) : (
              inner
            )}
          </Button>
        );
      })}
    </nav>
  );
}
