"use client";

import { cn } from "@/lib/utils";
import Link, { LinkProps } from "next/link";
import { usePathname } from "next/navigation";
import { ReactNode } from "react";

type Children = ReactNode | ((isActive: boolean) => ReactNode);

interface NavLinkProps extends LinkProps {
  children: Children;
  className?: string;
  target?: "_blank" | "_self" | "_parent" | "_top" | undefined;
  activeClassName?: string;
  notActiveClassName?: string;
  exact?: boolean;
}
export default function NavLink({
  className,
  href,
  children,
  target,
  exact,
  activeClassName,
  notActiveClassName,
  ...props
}: NavLinkProps) {
  const path = usePathname();
  const isActive = exact ? path === href : path.startsWith(href as string);

  return (
    <Link
      href={href}
      data-active={isActive}
      className={cn(className, isActive ? activeClassName : notActiveClassName)}
      target={target}
      {...props}
    >
      {typeof children === "function" ? children(isActive) : children}
    </Link>
  );
}
