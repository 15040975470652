"use client";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from "@/components/ui/dropdown-menu";
import { Button } from "@/components/ui/button";
import { BotIcon, ChevronDownIcon, PlusIcon } from "lucide-react";
import { Input } from "@/components/ui/input";
import Link from "next/link";
import { useParams } from "next/navigation";
import { useBots, useSingleBot } from "@/lib/hooks";
import { useEffect, useState } from "react";
import { useDebounce } from "@uidotdev/usehooks";
import { Skeleton } from "@/components/ui/skeleton";
import { CreateChatBot } from "@/app/(chatbot)/org/[orgId]/bot/_components/create-chat-bot";

export default function ChatbotDropdown() {
  const [open, setOpen] = useState(false);
  const { orgId, botId } = useParams();
  const botsQuery = useBots(orgId?.toString());
  const botQuery = useSingleBot(botId?.toString());

  const [search, setSearch] = useState("");
  const debouncedSearch = useDebounce(search, 500);

  const bots = botsQuery.data?.filter((bot) => {
    if (!debouncedSearch) return true;
    return bot.name.toLowerCase().includes(debouncedSearch.toLowerCase());
  });

  const hasBots = !!bots && bots?.length > 0;

  if (botsQuery.isPending) {
    return <Skeleton className="w-[13.5rem] h-10" />;
  }

  return (
    <DropdownMenu open={open} onOpenChange={setOpen}>
      <DropdownMenuTrigger asChild>
        <Button
          className="hidden md:flex items-center justify-start gap-x-2 text-left"
          variant="ghost"
        >
          <BotIcon className="h-5 w-5" />
          {botId && botQuery.isPending ? (
            <Skeleton className="w-32 h-6" />
          ) : botQuery.data ? (
            <span>{botQuery.data?.name}</span>
          ) : (
            <span className="min-w-[10ch]">Select bot</span>
          )}
          <ChevronDownIcon className="h-4 w-4" />
        </Button>
      </DropdownMenuTrigger>
      <DropdownMenuSeparator />
      <DropdownMenuContent>
        {(hasBots || debouncedSearch) && (
          <>
            <div className="px-2 py-2">
              <Input
                value={search}
                onChange={(e) => setSearch(e.target.value)}
                className="text-sm"
                placeholder="Search bot..."
                type="search"
              />
            </div>
            <DropdownMenuSeparator />
          </>
        )}

        <div className="flex items-center justify-between text-xs m-2 text-gray-500 font-medium">
          <span>Chatbots</span>
        </div>

        <div className="max-h-60 overflow-y-auto">
          {hasBots ? (
            bots?.map((bot) => (
              <DropdownMenuItem className="cursor-pointer" key={bot.id} asChild>
                <Link href={`/org/${bot.organization_id}/bot/${bot.id}/chat`}>
                  <BotIcon className="h-4 w-4 mr-2" />
                  {bot.name}
                </Link>
              </DropdownMenuItem>
            ))
          ) : (
            <div className="flex px-2 py-1.5 text-sm">
              <span className="text-gray-500 text-sm">No bot found</span>
            </div>
          )}
        </div>

        <DropdownMenuSeparator />
        <div className="px-2 py-2">
          <CreateChatBot
            variant="outline"
            className="w-full"
            onChatBotCreated={() => setOpen(false)}
          />
        </div>
      </DropdownMenuContent>
    </DropdownMenu>
  );
}
