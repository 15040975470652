import { create } from "zustand";

interface EmbedState {
  open: boolean;
  onOpenChange: (open: boolean) => void;
  shareOpen: boolean;
  shareOnOpenChange: (open: boolean) => void;
}

const useEmbed = create<EmbedState>((set) => ({
  open: false,
  shareOpen: false,
  shareOnOpenChange: (shareOpen) => set(() => ({ shareOpen })),
  onOpenChange: (open) => set(() => ({ open })),
}));

export default useEmbed;
