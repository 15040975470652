"use client";
import { Button, ButtonProps } from "@/components/ui/button";
import {
  useAccountInfo,
  useAuth,
  useRoleAuth,
  useUsageDetails,
} from "@/lib/hooks";
import { useParams, useRouter } from "next/navigation";
import { useState } from "react";
import { actionWrapper } from "@/lib/utils";
import { autoCreateBot } from "@/actions";
import { Plus } from "lucide-react";
import Spinner from "@/components/shared/Spinner";
import { toast } from "sonner";
import { Permission } from "@prisma/client";
import { useQueryClient } from "@tanstack/react-query";

export function CreateChatBot({
  onChatBotCreated,
  ...props
}: Omit<ButtonProps, "onClick" | "disabled"> & {
  onChatBotCreated?: (botId: string) => void;
}) {
  const client = useQueryClient();
  const { hasBotLimit } = useUsageDetails();
  const [loading, setLoading] = useState(false);
  const { user } = useAuth();
  const { orgId } = useParams();
  const { push } = useRouter();
  const { data: account } = useAccountInfo();

  const canCreate = useRoleAuth("bot", Permission.CREATE);
  async function create() {
    if (!orgId || !user?.id) return;
    try {
      setLoading(true);
      const bot = await actionWrapper(autoCreateBot(orgId.toString(), user.id));
      client.invalidateQueries({
        queryKey: ["account-usages", account?.id],
      });
      client.refetchQueries({
        queryKey: ["getAccountInfo"],
      });
      push(`/org/${orgId}/bot/${bot.id}/sources`);
      onChatBotCreated?.(bot.id);
    } catch (error) {
      console.log(error);
      toast.error("Failed to create chatbot.");
    } finally {
      setLoading(false);
    }
  }
  return (
    <Button
      disabled={!canCreate || loading || !hasBotLimit}
      onClick={create}
      {...props}
    >
      {loading ? <Spinner className="size-4" /> : <Plus className="size-4" />}
      Create Chatbot
    </Button>
  );
}
