"use client";
import { z } from "zod";
import { ReactNode, useState, useTransition } from "react";
import { toast } from "sonner";
import { Button } from "@/components/ui/button";
import { createOrganization } from "@/actions";
import { actionWrapper, cn } from "@/lib/utils";
import Spinner from "@/components/shared/Spinner";
import {
  Form,
  FormControl,
  FormDescription,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "@/components/ui/form";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from "@/components/ui/dialog";
import { zodResolver } from "@hookform/resolvers/zod";
import { useForm } from "react-hook-form";

import { Input } from "@/components/ui/input";
import { useParams } from "next/navigation";

const FormSchema = z.object({
  name: z.string().min(2, {
    message: "Organization name must be at least 2 characters.",
  }),
});

type CreateOrganizationProps = {
  onOrganizationCreated?: (orgId: string) => void;
} & (FirstChoice | SecondChoice);

type FirstChoice = {
  open: boolean;
  onOpenChange: (open: boolean) => void;
};

type SecondChoice = {
  children: ReactNode;
};

export default function CreateOrganization({
  onOrganizationCreated,
  ...props
}: CreateOrganizationProps) {
  const { orgId } = useParams<{ orgId: string }>();
  const [loading, setLoading] = useState(false);
  const [isPending, startTransition] = useTransition();

  const form = useForm<z.infer<typeof FormSchema>>({
    resolver: zodResolver(FormSchema),
    defaultValues: { name: "" },
  });

  async function onSubmit(data: z.infer<typeof FormSchema>) {
    try {
      setLoading(true);
      const org = await actionWrapper(createOrganization(data.name, orgId));
      onOrganizationCreated?.(org.id);
      if ("onOpenChange" in props) {
        props.onOpenChange(false);
      }
      form.reset();
    } catch (e) {
      console.log(e);
      toast.error("Failed to create organization.");
    } finally {
      setLoading(false);
    }
  }

  return (
    <Dialog
      open={"open" in props ? props.open : undefined}
      onOpenChange={"onOpenChange" in props ? props.onOpenChange : undefined}
    >
      {"children" in props && props.children}
      <DialogContent>
        <Form {...form}>
          <DialogHeader>
            <DialogTitle>Create Organization</DialogTitle>
            <DialogDescription>
              Create a new organization to manage your chatbots.
            </DialogDescription>
          </DialogHeader>
          <form autoComplete="off" onSubmit={form.handleSubmit(onSubmit)}>
            <FormField
              control={form.control}
              name="name"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>Organization Name</FormLabel>
                  <FormControl>
                    <Input {...field} />
                  </FormControl>
                  <FormDescription>
                    This will be the name of your organization.
                  </FormDescription>
                  <FormMessage />
                </FormItem>
              )}
            />
            <DialogFooter>
              <Button
                disabled={loading || isPending}
                className="relative"
                type="submit"
              >
                <span className={cn((loading || isPending) && "opacity-0")}>
                  Submit
                </span>
                {(loading || isPending) && (
                  <Spinner className="size-4 absolute inset-0 m-auto" />
                )}
              </Button>
            </DialogFooter>
          </form>
        </Form>
      </DialogContent>
    </Dialog>
  );
}
