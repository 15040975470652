"use client";
import CreateOrganization from "@/app/(chatbot)/org/_components/create-organization";
import ChatbotDropdown from "@/components/shared/chatbot-dropdown";
import { Button } from "@/components/ui/button";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from "@/components/ui/dropdown-menu";
import { Input } from "@/components/ui/input";
import { Skeleton } from "@/components/ui/skeleton";
import {
  useAuth,
  useOrganizations,
  useRoleAuth,
  useSingleOrganization,
  useUsageDetails,
} from "@/lib/hooks";
import useOrganization from "@/store/useOrganization";
import { PrismaOrganization } from "@/types";
import { Permission } from "@prisma/client";
import { useDebounce } from "@uidotdev/usehooks";
import {
  Building,
  ChevronDown,
  ChevronDownIcon,
  ChevronsUpDown,
  Plus,
} from "lucide-react";
import { useParams, useRouter } from "next/navigation";
import { useState } from "react";
import Link from "next/link";

export default function OrganizationDropdown() {
  const { user } = useAuth();
  const { orgId } = useParams();
  const { push } = useRouter();
  const [search, setSearch] = useState("");
  const debouncedSearch = useDebounce(search, 500);
  const [openOrgList, setOpenOrgList] = useState(false);
  const [openCreateDialog, setOpenCreateDialog] = useState(false);
  const { setOrganization, organization } = useOrganization();
  const organizationsQuery = useOrganizations(user?.id!);
  const canCreateOrg = useRoleAuth("org", Permission.CREATE);
  const { hasOrganizationLimit } = useUsageDetails();

  const organizations = organizationsQuery.data?.filter(({ Organization }) => {
    if (!debouncedSearch) return true;
    return Organization.name
      .toLowerCase()
      .includes(debouncedSearch.toLowerCase());
  });

  const singleOrganization = useSingleOrganization(
    orgId?.toString()! ??
      organization?.id ??
      organizations?.[0].Organization.id,
  );

  const hasOrganizations = !!organizations && organizations?.length > 0;

  const selectOrganization = (org: PrismaOrganization) => {
    setOrganization(org);
    push(`/org/${org.id}/bot`);
  };

  if (organizationsQuery.isPending) {
    return <Skeleton className="w-40 h-10" />;
  }

  return (
    <>
      <CreateOrganization
        open={openCreateDialog}
        onOpenChange={setOpenCreateDialog}
        onOrganizationCreated={(orgId) => {
          setOpenOrgList(false);
          push(`/org/${orgId}/bot`);
        }}
      />
      <DropdownMenu open={openOrgList} onOpenChange={setOpenOrgList}>
        <div className="grid grid-cols-[1fr_auto] gap-1">
          <Button
            className="flex items-center gap-x-2 justify-start text-left max-w-[14rem] hover:!bg-transparent pr-0"
            variant="ghost"
            asChild
          >
            <Link href={`/org/${singleOrganization.data?.id}/bot`}>
              <Building className="h-4 w-4" />
              {orgId && singleOrganization.isPending ? (
                <Skeleton className="w-52 h-6" />
              ) : singleOrganization.data ? (
                <span>{singleOrganization.data?.name}</span>
              ) : (
                <span className="min-w-[10ch]">Select organization</span>
              )}
            </Link>
          </Button>
          <DropdownMenuTrigger asChild>
            <Button
              variant="ghost"
              className="h-10 w-7 p-0 focus-visible:[box-shadow:none]"
            >
              <ChevronDown className="h-4 w-4" />
            </Button>
          </DropdownMenuTrigger>
        </div>
        <DropdownMenuContent align="start">
          {(hasOrganizations || debouncedSearch) && (
            <>
              <div className="px-2 py-2">
                <Input
                  value={search}
                  onChange={(e) => setSearch(e.target.value)}
                  className="text-sm"
                  placeholder="Search organization..."
                  type="search"
                />
              </div>
              <DropdownMenuSeparator />
            </>
          )}
          <div className="flex items-center justify-between text-xs m-2 text-gray-500 font-medium">
            <span>Organizations</span>
          </div>
          <div className="max-h-60 overflow-y-auto">
            {hasOrganizations ? (
              <>
                {organizations?.map(({ Organization }) => (
                  <DropdownMenuItem
                    className="cursor-pointer"
                    key={Organization.id}
                    onSelect={() => selectOrganization(Organization)}
                  >
                    <Building className="h-4 w-4 mr-2" />
                    {Organization.name}
                  </DropdownMenuItem>
                ))}
              </>
            ) : (
              <div className="flex px-2 py-1.5 text-sm">
                <span className="text-gray-500 text-sm">
                  No organizations found
                </span>
              </div>
            )}
          </div>
          {orgId && (
            <>
              <DropdownMenuSeparator />
              <div className="px-2 py-2">
                <Button
                  variant="outline"
                  disabled={!canCreateOrg || !hasOrganizationLimit}
                  onClick={() => {
                    setOpenOrgList(false);
                    setOpenCreateDialog(true);
                  }}
                >
                  <Plus className="size-4" />
                  Create Organization
                </Button>
              </div>
            </>
          )}
        </DropdownMenuContent>
      </DropdownMenu>

      {orgId && <ChatbotDropdown />}
    </>
  );
}
